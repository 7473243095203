import { defineStore } from 'pinia'
import type { Branch } from '~/types/branch'
import type { Provide } from '~/plugins/api'

export const useBranchStore = defineStore('branches', () => {
  const branches = ref<Branch[]>([])
  const filters = ref({})
  const isLoading = ref(false)
  const branch = ref<Branch>({} as Branch)
  const {
    $api: { branchService },
  }: Provide = useNuxtApp()

  const fetchBranches = async (params?: object) => {
    await branchService.getBranches(params).then((response: any) => {
      branches.value = response.data
    })
  }
  const fetchBranch = async (routeId: string | number) => {
    isLoading.value = true
    // Merging from /branches for get more info
    let extBranch = branches.value.find(b => b.id === Number(routeId))
    if (!extBranch) {
      await fetchBranches()
      extBranch = branches.value.find(b => b.id === Number(routeId))
    }
    await branchService.getBranch(routeId).then((response: any) => {
      // branch.value = Object.assign(response.data, JSON.parse(JSON.stringify(extBranch)))
      branch.value = response.data
    })
    isLoading.value = false
  }
  const resetState = (): void => {
    useBranchStore().$reset()
  }

  return {
    resetState,
    branch,
    filters,
    branches,
    fetchBranches,
    fetchBranch,
    isLoading,
  }
})
