<script setup lang="ts">
defineProps<{
  width?: number
}>()
// global modal component
const modelValue = defineModel()
onMounted(() => {
  const closeModalOnEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && modelValue.value)
      modelValue.value = false
  }

  window.addEventListener('keydown', closeModalOnEsc)

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', closeModalOnEsc)
  })
})

watchEffect(() => {
  if (process.client) {
    const body = document.body
    const scrollBarWidth = window.innerWidth - body.clientWidth

    if (modelValue.value) {
      document.body.style.overflow = 'hidden'
      if (scrollBarWidth > 0)
        body.style.paddingRight = `${scrollBarWidth}px`
    }
    else {
      document.body.style.overflow = 'auto'
      body.style.paddingRight = '0px'
    }
  }
})

// fix teleport bug in vue runtime
const mounted = ref(false)
onMounted(() => {
  mounted.value = true
})
</script>

<template>
  <Teleport v-if="mounted" to="body">
    <Transition name="modal" v-bind="$attrs">
      <div
        v-if="modelValue"
        class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-40"
        @click="modelValue = !modelValue"
      >
        <div class="inset-center" @click.stop>
          <div
            class="bg-white rounded-lg overflow-hidden shadow-xl p-6"
            :class="$props.width ? `w-[${$props.width}px]` : 'w-[544px]'"
            role="dialog"
            aria-modal="false"
            aria-labelledby="modal-headline"
          >
            <div v-bind="$attrs" class="flex flex-col">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.inset-center {
  overflow-y: auto;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 200ms, transform 200ms;
}
.modal-enter-from, .modal-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.modal-enter-to, .modal-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
