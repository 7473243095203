import { defineStore } from 'pinia'

export const useErrorToast = defineStore('errorToast', () => {
  const isVisible = ref(false)

  const show = () => isVisible.value = true
  const hide = () => isVisible.value = false

  return { isVisible, show, hide }
})
