<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isVisible: boolean
  }>(),
  {
    isVisible: false,
  },
)
const reload = () => window.history.go()
</script>

<template>
  <Transition name="show">
    <div
      v-if="props.isVisible"
      class="fixed right-10 bottom-5 z-10 py-2 px-4 rounded-md text-sm bg-red-500 text-white opacity-70 hover:opacity-100 cursor-default duration-150"
    >
      <h1 class="text-h4 mb-2 text-center">
        Ошибка сервера
      </h1>
      <p>Не переживайте, мы уже знаем о проблеме</p>
      Просто попробуйте
      <span
        class="border-b-[1px] border-blue-300 text-blue-300 cursor-pointer hover:border-blue-400 hover:text-blue-400 duration-150"
        @click="reload()"
      >перезагрузить страницу</span>
    </div>
  </Transition>
</template>

<style scoped>
.show-enter-active,
.show-leave-active {
  transition: opacity 200ms ease;
}

.show-enter-from,
.show-leave-to {
  opacity: 0;
}
</style>
